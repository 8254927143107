import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import Layout from "components/Layout"
import ContentCard from "components/ContentCard"

const Services = ({ services, meta }) => (
  <>
    <Helmet
      title={`Services | About`}
      titleTemplate={`%s | ${meta.title}`}
      meta={[
        {
          name: `description`,
          content: meta.description,
        },
        {
          property: `og:title`,
          content: `Services | About`,
        },
        {
          property: `og:description`,
          content: meta.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: meta.author,
        },
        {
          name: `twitter:title`,
          content: meta.title,
        },
        {
          name: `twitter:description`,
          content: meta.description,
        },
      ].concat(meta)}
    />
    <Layout>
      <>
        {services.body.map((service, i) => (
          <ContentCard
            key={i}
            subline={service.fields[0].subtitel}
            title={service.fields[0].titel}
            description={service.fields[0].text}
            thumbnail={service.fields[0].image}
          />
        ))}
      </>
    </Layout>
  </>
)

export default ({ data }) => {
  const services = data.prismic.allServices.edges.slice(0, 1).pop()
  const meta = data.site.siteMetadata
  if (!services) return null

  return <Services services={services.node} meta={meta} />
}

Services.propTypes = {
  services: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    prismic {
      allServices {
        edges {
          node {
            body {
              ... on PRISMIC_ServiceBodyService_block {
                fields {
                  image
                  subtitel
                  text
                  titel
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
